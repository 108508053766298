@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
@import 'style/index';

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  min-width: 320px;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', Arial, sans-serif;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.sidebar {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  /* WebKit */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &_desktop {
    flex-shrink: 0;
    width: 62px;
    transition: width 0.5s;
  }

  &_opened {
    width: 320px;
  }

  &_mobile {
    position: absolute;
    z-index: 5000;
    flex-shrink: 1;
    width: 100%;
  }

  &__toggle {
    position: sticky;
    bottom: 24px;
  }
}

.main {
  height: 100vh;
}

.header {
  min-height: 62px;
}

/* Bootstrap additional styles */

// TODO: figure iut what was the idea behind this style
// .btn + .btn {
// margin-left: 0.5rem;
// }

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.min-width-0 {
  min-width: 0 !important;
}

.overflow-visible {
  overflow: visible !important;
}

.btn-group:empty {
  display: none;
}

.btn-light {
  border: 1px solid #dee2e6;
}

.text-pre-wrap {
  white-space: pre-wrap !important;
}

.text-smaller {
  font-size: 0.8em;
}

.dropdown-item-danger {
  color: #dc3545;

  &:hover:not(&:active) {
    color: #dc3545;
  }
}

.custom-switch {
  z-index: 0 !important;
}

.custom-control-label {
  cursor: pointer;
}

.card_moved {
  z-index: 9999;
}

.flex-basis-0 {
  flex-basis: 0 !important;
}

// https://react-hook-form.com/api/useform/register
// If you want to prevent users from updating the input, you can use readOnly
input[type='checkbox'][readOnly] {
  pointer-events: none;

  & ~ .custom-control-label {
    color: #6c757d;
    pointer-events: none;

    &::before {
      background-color: #e9ecef;
      pointer-events: none;
    }
  }

  &:checked {
    & ~ .custom-control-label {
      &::before {
        background-color: rgba(0, 123, 255, 0.5);
      }
    }
  }
}
