.part-graph {
  &__node {
    width: 300px;
  }

  &__node-header {
    display: flex;
  }

  &__node-label {
    width: calc(100% - 20px);
  }

  &__node-dropdown {
    align-items: flex-start;
  }

  &__node-reference {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__node-option {
    display: block;
    width: 100%;
    overflow: hidden;
    font-size: 0.75em;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__card-group {
    &_multiple {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: -10px;
      margin-left: -10px;
      padding: 5px;
      border: 5px solid rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      transition: border-color 0.2s ease;

      &:hover {
        border-color: white;
      }
    }
  }
}
