.rounded-box {
  $this: &;

  &__wrapper {
    display: flex;
    padding: 10px;
    text-align: left;
    background: none;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }

  &__wrapper:hover {
    box-shadow: 0 0 4px #bdbdbd;
  }
}
